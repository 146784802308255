// Navbar do site
import React from 'react';
import { useEffect } from 'react';
// CSS
import './Cabecalho.css';

function Cabecalho() {
    function refreshPage() {
        window.location.reload(true);
    }
    function scrollToTop() {
        let element = document.querySelector('.container');
        element.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(() => {
        let container = document.querySelector('.container');
        // Add event listener to buttons
        let buttons = document.querySelectorAll('.navbar a');
        buttons.forEach(button => {
            button.addEventListener('click', function() {
                // Get attr data from button
                let data = button.getAttribute('data');
                if (!data) return;
                //Get element using data attr
                let element = document.querySelector(data);
                //Get navbar height
                let navbar = document.querySelector('.navbar');
                let navbarHeight = navbar.offsetHeight;
                let y = element.offsetTop - navbarHeight;
                // Scroll to element
                container.scrollTo({ top: y, behavior: 'smooth'});
                });
        });
    }, []);
    return (
        <nav className='navbar'>
            <div className='container'>
                <img src='/assets/Logo.png' onClick={refreshPage} alt='Logo' />
                <div className='rightSection'>
                    <div className='navButtons'>
                        <a className="active" onClick={scrollToTop}>Início</a>
                        <a data="#sobre">Sobre</a>
                        <a data="#ajude">Ajude</a>
                        <a data="#contato">Contato</a>
                        <a href='/transparencia'>Transparência</a>
                    </div>
                    <div className='socialMedia'>
                        <a href='https://www.facebook.com/cimmulher/' target='_blank' rel='noreferrer'>
                            <img src='/assets/facebook.png' alt='Facebook' />
                        </a>
                        <a href='https://www.instagram.com/cimmulher/' target='_blank' rel='noreferrer'>
                            <img src='/assets/instagram.png' alt='Instagram' />
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Cabecalho;