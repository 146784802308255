// Navbar do site
import React from 'react';
import "./Rodape.css";

function Rodape() {
    return (
        <footer>
            <div className="wrapper">
                <p>
                Copyright ©2024 CIM Mulher. Todos direitos reservados.
                </p>
                <div className='right'>
                    <a>Política de Privacidade e Cookies</a>
                    <a>Termos de Uso</a>
                </div>
            </div>
        </footer>
    );
}

export default Rodape;