import React from "react";
import "./Contato.css";

function Contato() {
  const formHandle = () => {
    return (e) => {
      // Get form inputs
      const form = e.target;
      const data = new FormData(form);
      const name = data.get("nome");
      const email = data.get("email");
      const subject = data.get("assunto");
      const message = data.get("mensagem");
      // Send email
      e.preventDefault();
      try {
        fetch("https://cimmulher.org.br/mailer.php", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            name: name,
            email: email,
            subject: subject,
            message: message,
          }).toString(),
        })
          .then((response) => {
            console.log("Response:", response.text());
            if (response.status === 200) {
              form.reset();
              alert("Mensagem enviada com sucesso!");
            }
          })
          .catch((error) => {
            console.error("Erro:", error);
          });
      } catch (err) {
        console.error("Erro ao enviar mensagem: ", err);
      }
    };
  };

  return (
    <section id="contato">
      <div className="wrapper">
        <h1 className="decTitle">Contato</h1>
        <div className="textblock1">
          <div>
            Em caso de situação de risco, entre em contato através do nosso
            plantão 24h:{" "}
            <a href="tel:+55991636238" className="phoneNumber">
              (15) 99163-6238
            </a>
          </div>
          <div className="desc">
            Entre em contato através de nosso email{" "}
            <a
              href="mailto:contato@cimmulher.org.br?subject=Preciso%20de%20ajuda!"
              className="mailLink"
            >
              contato@cimmulher.org.br
            </a>{" "}
            ou preenchendo este formulário
          </div>
        </div>
        <div className="textblock2">
          <form className="formblock" onSubmit={formHandle()} method="post">
            {/* Form row with NOME and EMAIL */}
            <div className="formrow">
              <div className="formgroup-half">
                <label htmlFor="nome">Nome</label>
                <input type="text" id="nome" name="nome" />
              </div>
              <div className="formgroup-half">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" />
              </div>
            </div>
            {/* Form row with ASSUNTO */}
            <div className="formrow">
              <div className="formgroup">
                <label htmlFor="assunto">Assunto</label>
                <input type="text" id="assunto" name="assunto" />
              </div>
            </div>
            {/* Form row with MENSAGEM */}
            <div className="formrow">
              <div className="formgroup">
                <label htmlFor="mensagem">Mensagem</label>
                <textarea id="mensagem" name="mensagem" />
              </div>
            </div>
            {/* Form row with SUBMIT */}
            <div className="formrow">
              <button type="submit" className="submitBtn">
                Enviar
              </button>
            </div>
          </form>
          <div className="decorationblock">
            <img src="./assets/contact-decoration.png" alt="Ilustração" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contato;
