import React from 'react';
import { useEffect } from 'react';
import './Inicio.css';

function Inicio() {
    useEffect(() => {
        // Add event listener to buttons
        let buttons = document.querySelectorAll('.inicio button');
        buttons.forEach(button => {
            button.addEventListener('click', function() {
                // Get sectId from data attr
                let sectId = this.getAttribute('data');
                // Get element using sectId
                let element = document.querySelector(sectId);
                // Scroll to element
                element.scrollIntoView({ behavior: 'smooth' });
            });
        });
    }, []);

    return (
        <section id="inicio" className='inicio'>
            <div className="slogan">
                <h1>Gente foi feita para ser feliz!</h1>
                <button data="#contato">Preciso de Ajuda!</button>
            </div>
            <img src="/assets/happy-woman.png" alt="Imagem de uma pessoa feliz" />
        </section>
    );
}

export default Inicio;