import React from "react";
import Rodape from "../components/Rodape";
import { useEffect } from "react";
import './Transparencia.css';

function Transparencia() {

  useEffect(() => {
    // Event listeners for the nav buttons, when clicked switch divs with iframes
    let navButtons = document.querySelectorAll('#navTransp a');
    navButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        let data = e.target.getAttribute('data');
        console.log(data);
        // Get element
        let content = document.querySelector(data);
        // Style it to display block and opacity 1
        content.style.display = 'block';
        setTimeout(() => {
          content.style.opacity = 1;
        }, 100);
        // Get all other elements
        let otherContents = document.querySelectorAll('#transparenciaContent1, #transparenciaContent2');
        // For each element, if it's not the clicked element, style it to display none and opacity 0
        otherContents.forEach((otherContent) => {
          if (otherContent !== content) {
            otherContent.style.display = 'none';
            setTimeout(() => {
              otherContent.style.opacity = 0;
            }, 100);
          }
        });
      });
    });
  }, []);

  useEffect(() => {
    // Back button
    let backButton = document.querySelector('#backButton');
    backButton.addEventListener('click', () => {
      window.location.href = '/';
    });
  }, []);


  return (
    <div id="transparencia">
      <div className="container">
        <div className="body">
          <div id="backButton">
          </div>
          <div id="navTransp">
              <a data="#transparenciaContent1">CIM MULHER</a>
              <a data="#transparenciaContent2">PMS/SECID</a>
          </div>
          <div id="transparenciaContent1">
            <iframe src="https://drive.google.com/embeddedfolderview?id=1Cys8e9HBXu--0rPNWS-rI91ttTE6kiSM#list" title="CIM MULHER" width="100%" height="100%"></iframe>
          </div>
          <div id="transparenciaContent2">
            <iframe src="https://servicos.sorocaba.sp.gov.br/pubcon-consulta/#/publicacoes?filter_fields=assuntoSiat%2aCIM%20MULHER" title="PMS/SECID" width="100%" height="100%"></iframe>
          </div>
        </div>
      </div>
      <Rodape />
    </div>
  );
}

export default Transparencia;