import React from 'react';
import FloatBtn from './components/FloatBtn';
import Cabecalho from './components/Cabecalho';
import Inicio from './components/Inicio';
import Sobre from './components/Sobre';
import Ajude from './components/Ajude';
import Contato from './components/Contato';
import Rodape from './components/Rodape';
import Separator from './components/SectionSeparator';
import { useEffect } from 'react';

function App() {
  // On window load, check if URL contains route /ajude
  useEffect(() => {
    // Wait 1 second for the page to load
    setTimeout(() => {
      // if (window.location.href.includes('/ajude')) {
      //   // Trigger button click on .navButtons a element that has attr data="#ajude"
      //   let ajudeButton = document.querySelector('.navButtons a[data="#ajude"]');
      //   ajudeButton.click();
      // }
      // Not only for /ajude, but for all routes
      let currentRoute = window.location.pathname;
      let currentRouteWithoutSlash = currentRoute.substring(1);
      if (currentRouteWithoutSlash.length > 0) {
        // Trigger button click on .navButtons a element that has attr data="#ajude"
        let currentButton = document.querySelector('.navButtons a[data="#' + currentRouteWithoutSlash + '"]');
        // If query selector found the button, click it
        if (currentButton) {
          currentButton.click();
        }
      }
    }, 500);
  }, []);
  // Scrollchecker
  useEffect(() => {
    // Based on .container scrolls, change the class .active on .navbar a elements
    let element = document.querySelector('.container');
    let navbarLinks = document.querySelectorAll('.navbar a');
    let sections = document.querySelectorAll('section');
    let sectionsArray = Array.from(sections);
    let navbarLinksArray = Array.from(navbarLinks);
    let currentSection = 0;
    let currentLink = 0;
    let scrollChecker = () => {
      let scrollY = element.scrollTop;
      // scrollY + .navbar height
      // Get navbar height
      let navbar = document.querySelector('.navbar');
      let navbarHeight = navbar.offsetHeight * 1.75;
      scrollY += navbarHeight;
      let sectionTops = sectionsArray.map((section) => section.offsetTop);
      let sectionBottoms = sectionsArray.map((section) => section.offsetTop + section.offsetHeight);
      for (let i = 0; i < sectionsArray.length; i++) {
        if (scrollY >= sectionTops[i] && scrollY < sectionBottoms[i]) {
          currentSection = i;
          currentLink = i;
        }
      }
      navbarLinksArray.forEach((link) => link.classList.remove('active'));
      navbarLinksArray[currentLink].classList.add('active');
    }
    element.addEventListener('scroll', scrollChecker);
  }, []);
    return (
    <div className='container'>
      <FloatBtn />
      <Cabecalho />
      <Inicio />
      <Separator />
      <Sobre />
      <Separator />
      <Ajude />
      <Separator />
      <Contato />
      <Rodape />
    </div>
  );
}

export default App;