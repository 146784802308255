import React from 'react';
import "./SectionSeparator.css";

function Separator() {
    return (
        <div className="separator">
            <hr/>
        </div>
    );
}

export default Separator;