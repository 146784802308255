import React from 'react';
import { useEffect } from 'react';
import './Ajude.css';

function Ajude() {
    // Clipboard copy for pix without duplication, verifying if it is already copied
    const handleClipboardCopy = () => {
        let text = '01.944.279/0001-24';
        let pixClipboard = document.getElementById('pixClipboard');
        pixClipboard.addEventListener('click', () => {
            navigator.clipboard.writeText(text)
            .then(
                () => {
                    // Query #notifier
                    let notifier = document.getElementById('notifier');
                    // Opacity 1
                    notifier.style.opacity = '1';
                    // Remove text after 3 seconds
                    setTimeout(() => {
                        notifier.style.opacity = '0';
                    }, 3000);
                    return;
                }
            )
            .catch(
                () => {
                    // console.log('Erro ao copiar a chave PIX para a área de transferência!');
                    return;
                }
            );
        });
    }     
        
    useEffect(() => {
        handleClipboardCopy();
    }, []);
    return (
        <section id="ajude">
            <div className='wrapper'>
                <h1 className='decTitle'>Ajude</h1>
                <div className='insideWrapper'>
                    <div className='women'>
                        <img src='/assets/women-decoration.png' alt='Mulheres' />
                    </div>
                    <div className='texts'>
                        <h3>
                            Veja como você pode contribuir com o CIM Mulher:
                        </h3>
                        <div className="wrapBlocks">
                            <div className='block1'>
                                <h3>
                                    Transferência Bancária:
                                </h3>
                                <p>
                                Ajude o CIM Mulher a continuar amparando mulheres e crianças, faça sua doação!
                                </p>
                                <p>
                                TRANSFERÊNCIA BANCÁRIA:<br></br>Caixa Econômica Federal | Banco nº: 104<br></br>Agência: 2757 | Conta Jurídica: 003-1351-6
                                </p>
                            </div>
                            <div className='block2'>
                                <h3>
                                    Doação de itens:
                                </h3>
                                <div>
                                Para doação de itens como alimentos, vestuário, equipamentos, higiene pessoal ou limpeza é possível entrar em contato através de <a href='tel:+5515991636249'>(15) 99163-6249</a>
                                </div>
                                <div>
                                Para mais informações envie um email para:<br></br><a href='mailto:contato@cimmulher.org.br'>contato@cimmulher.org.br</a>
                                </div>
                            </div>
                        </div>
                        <div className="wrapBlocks">
                            <div className='block1'>
                                <h3>
                                Contribuição por PIX:
                                </h3>
                                <p>
                                Além da opção de transferência bancária, agora temos a transferência por PIX, 24h e livre de taxas!
                                </p>
                                <div>
                                Chave PIX (CNPJ):<br></br>CNPJ: 01.944.279/0001-24
                                    <div id="pixClipboard">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                                        </svg>
                                    </div>
                                    <div id="notifier">Chave PIX copiada!</div>
                                </div>

                                
                            </div>
                            <div className='block2'>
                                <img src='/assets/qr-code.svg'></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Ajude;