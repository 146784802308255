// Navbar do site
import React from 'react';
import './FloatBtn.css';

function FloatBtn() {
    function scrollToTop() {
        // Scroll .container to top
        document.querySelector('.container').scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    return (
        <div className='floatingButton' onClick={scrollToTop}>
        </div>
    );
}

export default FloatBtn;