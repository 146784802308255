import React from 'react';
import Separator from './SectionSeparator';
import './Sobre.css';
function Sobre() {
    var cavr1 = '100.678';
    var cavr2 = '9.292';
    var cavr3 = '57';
    var cerav1 = '1.923';
    var cerav2 = '6.161';
    var cerav3 = '57';
    return (
        <section id="sobre">
            <div className='wrapper'>
                <h1 className='decTitle'>Sobre Nós</h1>
                <div className='aboutText'>
                    <p>
                    Desde 1997, o CIM Mulher realiza um programa de apoio aos casos de violência doméstica, trabalhando com a orientação e o acolhimento de famílias vítimas de agressões, implementando também um trabalho de desconstrução da opressão social, além do incentivo ao empoderamento e autonomia da mulher e seus filhos.
                    </p>
                </div>
                <div className='CAVR'>
                    <div className='logo'>
                        <img src='/assets/CAVR.svg' alt='CAVR' />
                    </div>
                    <div className='texts'>
                        <h2>CAVR</h2>
                        <p>
                        A CAVR - Casa Abrigo Valquíria Rocha possibilita o acolhimento dessas famílias dando a elas um abrigo temporário e fornecendo serviços e atividades que resgatam a autonomia das mulheres.<br></br><br></br>
                        A mulher pode contar com atendimentos psicológicos, apoio jurídico e outras atividades através de projetos sociais. Além de poder participar de atividades como salão de beleza (cabelo e manicure) e artesanato que são meios delas se inserirem no mercado de trabalho e garantir sua independência.<br></br><br></br>
                        A estrutura conta com quartos, sala com televisão, cozinha e área de lazer para crianças, além de cinco refeições diárias para as mães e seus filhos.
                        </p>
                    </div>

                </div>
                <div className='infosCAVR'>
                    <div className='infos'>
                        <div className='card'>
                            <h3>Acolhimentos</h3>
                            <h3>{cavr2}</h3>
                            <p>O CIM Mulher atende desde 1997, mas só iniciamos a contabilizar os acolhimentos em 2007. Até 2023, assistimos, apoiamos, orientamos e acolhemos {cavr2} vítimas de agressões.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Atendimentos</h3>
                            <h3>{cavr1}</h3>
                            <p>De 2007 a 2023 conseguimos atender e ajudar a melhorar as vidas de {cavr1} pessoas.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Cobertura</h3>
                            <h3>{cavr3} - Cidades</h3>
                            <p>Atendemos a Região Metropolitana de Sorocaba, possibilitando o acolhimento e atendimento de quase 2 milhões de beneficiários.
                            </p>
                        </div>
                    </div>
                </div>
                <Separator />
                    <div className='CERAV'>
                        <div className='texts'>
                            <h2>CERAV</h2>
                            <p>
                            O espaço de reabilitação do agressor supre uma lacuna no trabalho de combate à violência contra mulheres, levando-se em consideração que a demanda e grande em nossa região não apenas contribui para reduzir as reincidências, como concorre também para a proteção emocional do próprio agressor, que adquirira a possibilidade de conviver melhor com a sociedade e com a família no seu particular.
                            <br></br><br></br>
                            Diante deste fato, em 2014 foi criado o CERAV - Centro Especializado de Reabilitação do Autor em Violência Doméstica,  e o funcionamento dos grupos reflexivos com os autores em situação de violência doméstica, vem de encontro a uma nova perspectiva de enfrentamento e prevenção à violência.
                            <br></br><br></br>
                            O CERAV, é mais um componente derivado do trabalho iniciado após a fundação do CIM e também, desde sua criação, vem mudando vidas e contribuindo para a evolução da nossa sociedade.
                            </p>
                        </div>
                        <div className='logo'>
                            <img src='/assets/CERAV.svg' alt='CERAV' />
                        </div>
                    </div>
                    <div className='infosCERAV'>
                    <div className='infos'>
                        <div className='card'>
                            <h3>Acolhimentos</h3>
                            <h3>{cerav2}</h3>
                            <p>Quantidade de indivíduos que passaram pelo nosso programa de reabilitação, contando os que foram encaminhados por outros pontos de apoio.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Atendimentos</h3>
                            <h3>{cerav1}</h3>
                            <p>O CERAV atende desde 2014, este número representa os atendimentos não direcionados de outros pontos de apoio.
                            </p>
                        </div>
                        <div className='card'>
                            <h3>Cobertura</h3>
                            <h3>Comarca de Sorocaba</h3>
                            <p>O serviço do CERAV está disponível para a região de Sorocaba e outros municípios da comarca.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sobre;